<template>
  <div class="'standart-events">
    <div v-if="isMobileScreen && showSponsors" class="sponsors-section">
      <div
        v-for="({ details, id }) in sponsors"
        :key="id"
        class="sponsor-div"
      >
        <img :src="details.logo" >
        <span class="sponsor-name">{{ details.name }}</span>
      </div>
    </div>
    <b-row class="event-day-year">
      <b-col cols="1" class="pl-0">
        <p v-if="!isMobileScreen" class="single-event-global-date m-0">
            {{ $formatDate.getUTCDate(award.start_date) }} -
                {{ $formatDate.getUTCDate(award.end_date) }} <br />
                {{ $formatDate.getUTCMonth(award.start_date) }}<br />
                {{ $formatDate.getUTCYear(award.start_date) }}
        </p>
        <router-link v-else :to="{ name: 'ccAllEvents' }" exact>
          <i class="fas fa-arrow-left" />
        </router-link>
      </b-col>
      <b-col>
        <p class="event-text m-0">{{ award.name }}</p>
      </b-col>
    </b-row>
    <div class="event-img mt-1">
      <img style="opacity:0.4;" :src="award.main_banner" :alt="award.name" />
    </div>
    <div id="left-event-buttons" v-if="!isEndedAward()">
      <router-link v-if="isOpenRegistration" :to="{ name: 'EnterAward', params: { awardId } }" class="enterArea">
        <button class="book-this-award">
          <i class="fa fa-trophy" /> Enter Awards
        </button>
      </router-link>
      <button
        v-if="!isBooked && showBookButton"
        class="book-this-award bookArea"
        @click="openBookAward()"
      >
        <i class="fa fa-check" /> Book Ceremony Seat
      </button>
      <router-link :to="{ name: 'BecomeSponsorAward', awardId }" class="sponsorArea">
        <button class="becomeASponsor-btn">Become a Sponsor</button>
      </router-link>
    </div>

    <div class="events-buttons">
      <b-row id="all-event-btns">
        <router-link
          v-if="isExistWinners"
          class="award-event-button-name"
          :to="{ name: 'AwardWinners' }"
        >Winners</router-link>
        <router-link
          v-if="isExistShortlisted && !isExistWinners"
          class="award-event-button-name"
          :to="{ name: 'AwardShortlisted' }"
        >Shortlisted</router-link>
        <router-link
          v-for="{ name, route } in components"
          :key="route"
          class="award-event-button-name"
          :to="{ name: route }"
        >{{ name }}</router-link>
        <router-link
          v-if="isEndedAward()"
          class="award-event-highlights-button"
          :to="{ name: 'AwardHighlights' }"
        >Highlights</router-link>
      </b-row>
      <keep-alive>
        <router-view :key="$route.fullPath"/>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import axios from 'axios';
import store from '../../store';
import { getOwnerId } from '../../utils/getOwnerId';


export default {
  name: 'GlobalAwards',
  data() {
    return {
      components: [
        {
          name: 'Info',
          route: 'AwardInfo',
        },
        {
          name: 'Map',
          route: 'AwardMap',
        },
        {
          name: 'Agenda',
          route: 'AwardAgenda',
        },
        {
          name: 'Categories',
          route: 'AwardCategories',
        },
        {
          name: 'Jury',
          route: 'AwardJury',
        },
        {
          name: 'Media',
          route: 'AwardMedia',
        },
        {
          name: 'Terms & Conditions',
          route: 'AwardTerms&Conditions',
        },
      ],
      highlights: {
        name: 'Highlights',
        route: 'AwardHighlights',
      },
      awardId: '',
      isExistWinners: false,
      isExistShortlisted: false,
      awardIsNotEnded: false,
      isEntityMatch: false,
      awardFinancialEntity: null,
      showBookButton: false,
      registrationData: null,
      invoices: [],
      sponsors: [],
      showSponsors: false,
      isOpenRegistration: false,
    };
  },
  computed: {
    ...mapGetters({
      award: 'awards/award',
      userInfo: 'user/fetchUserInfo',
      awardPrices: 'events/awardPrices',
      isMobileScreen: 'isMobileScreen',
    }),
    isBooked() {
      const index = this.invoices.findIndex(invoice => invoice.event === this.award.url || invoice.award === this.award.url);
      return index !== -1;
    },
  },
  async mounted() {
    await this.fetchUserInvoices(getOwnerId()).then(
      ({ results }) => (this.invoices = results),
    ).catch(undefined);
    const { awardId } = this.$route.params;
    await this.fetchAwardPrices(awardId);
    this.awardId = this.$route.params.awardId;
    try {
      if (awardId) {
        this.registrationData = await this.fetchRegistrationList(awardId);
        this.isOpenRegistration = this.registrationData.length ? new Date(this.registrationData[0].submission_deadline) > Date.now() : false;
        await this.fetchAward(awardId).then(({ end_date, financial_entity }) => {
          this.awardFinancialEntity = financial_entity;
          this.awardIsNotEnded = end_date > this.moment().format('YYYY-MM-DD');
        });
        await this.fetchPaymentEntities().then(({ results }) => {
          results.forEach((entity) => {
            if (this.awardFinancialEntity === entity.url && entity.bank_accounts.length) this.isEntityMatch = true;
          });
        });
        if (this.isEntityMatch && this.awardIsNotEnded) this.showBookButton = true;
      }
    } catch (err) {
      console.log(err);
    }
    if (this.registrationData.length) {
      const { short_listed_publication, winners_publication } = this.registrationData[0];

      this.isExistShortlisted = short_listed_publication && new Date(short_listed_publication) <= Date.now();
      this.isExistWinners = winners_publication && new Date(winners_publication) <= Date.now();
    }
  },
  methods: {
    isEndedAward() {
      return !this.moment().isBefore(this.award.end_date);
    },
    ...mapActions({
      fetchAward: 'awards/fetchAward',
      fetchAwardPrices: 'events/fetchAwardPrices',
      fetchPaymentEntities: 'companies/fetchPaymentEntities',
      fetchRegistrationList: 'awards/fetchRegistrationList',
      fetchUserInvoices: 'user/fetchUserInvoices',
    }),
    moment(date) {
      return date ? moment(new Date(date).toUTCString()) : moment(new Date().toUTCString());
    },
    eventDays(start, end) {
      let day = this.moment(start).format('DD');
      if (start !== end) {
        day = `${this.moment(start).format('DD')} - ${this.moment(end).format('DD')}`;
      }
      return day;
    },
    async openBookAward() {
      const config = {
        headers: { Authorization: `Bearer ${this.$store.getters['auth/access']}` },
      };
      if (this.awardPrices[0]) {
        const { data: { id } } = await axios(this.awardPrices[0].award, config);
        this.$router.push({ name: 'BookAwardDetails', params: { id } });
      }
    },
  },
};
</script>

<style scoped>
.standart-events {
  position: relative;
}
#all-event-btns {
  margin: 0 0 5px 0;
  overflow: auto;
  display: grid;
  grid-auto-flow: column;
  padding-bottom: 0px;
  overflow-y: hidden;
  justify-content: start;
}
.award-event-button-name,
.award-event-highlights-button {
  font-size: 10px;
  background-color: #ffffff;
  color: #bd9d5f;
  border-radius: 0 10px 10px 10px;
  margin-right: 4px;
  text-decoration: none;
  padding: 4px 8px;
  width: max-content;
  line-height: 1.17;
}
.award-event-highlights-button {
  background-color: #bd9d5f;
  color: white;
  margin-right: 1px;
}
/*  media mobile */
@media (max-width: 768px) {
  .award-event-button-name.router-link-exact-active,
  .award-event-button-name.router-link-active {
    background-color: #fff !important;
    color: #bd9d5f !important;
  }
}
.router-link-exact-active,
.router-link-active {
  color: #fff !important;
  background-color: #bd9d5f;
}
.event-day-year {
  background: white;
  height: 61px;
  margin: 0;
  border-radius: 0 10px 0 0;
}
.single-event-global-date {
  width: 61px;
  font-size: 14px;
  color: #464646;
  line-height: 16px;
  padding-top: 6px;
  font-weight: 400;
  text-align: center;
}
.event-text {
  font-size: 22px;
  color: rgb(42, 42, 42);
  font-family: Montserrat;
  line-height: 60px;
  text-align: center;
}
.event-img {
  height: 240px;
  display: flex;
  background-color: #c0c0c0;
}
.event-img > img {
  width: 100%;
  height: 242px;
}
button {
  margin-right: 3px;
}
.events-buttons {
  width: 100%;
  margin:10px 0;
  padding-top: 10px;
}

#left-event-buttons {
  position: absolute;
  right: 20px;
  top: 6rem;
  width: 170px;
}
.book-this-award {
  background: #bd9d5f;
  border: unset;
  color: white;
  border-radius: 0 10px 10px 10px;
  width: 100%;
  padding: 10px 0px;
  font-size: 14px;
  outline: none;
  box-shadow: unset;
  margin-bottom: 8px;
}
.becomeASponsor-btn {
  background: #ffffff;
  border: unset;
  border-radius: 0px 10px 10px 10px;
  color: #bd9d5f;
  width: 100%;
  padding: 10px 0;
  font-size: 14px;
  outline: none;
  box-shadow: unset;
}
@media (max-width: 768px) {
  .event-day-year {
    padding: 12px;
    height: 47px;
    border-radius: 0 10px 0 0;
  }
  .event-day-year>div>a {
    background-color: #f3f3f3;
    padding: 5px;
    border-radius: 50%;
    color: #333;
  }
  .event-text {
    font-size: 13px;
    color: #333;
    line-height: 25px;
  }
  .award-event-button-name,
  .award-event-highlights-button {
    color: #7f7f7f;
    background-color: #f3f3f3;
  }
  .bookArea { grid-area: book; }
  .sponsorArea { grid-area: sponsor; }
  .enterArea { grid-area: enter; }
  #left-event-buttons {
    position: initial;
    width: 100%;
    margin-top: 2px;
    display: grid;
    grid:
      'enter sponsor'
      'book book';
    grid-gap: 2px;
  }
  .bookArea {
    background-color: #fff;
    color: #bd9d5f;
    width: 100%;
    border-radius: 0 0 10px 10px;
    padding: 8px;
    font-size: 11px;
    margin: 0;
  }
  .becomeASponsor-btn,
  .enterArea>button {
    width: 100%;
    padding: 8px;
    font-size: 11px;
    margin: 0;
    background-color: #fff;
    color: #bd9d5f;
  }
  .events-buttons {
    margin-top: 2px;
  }
  .sponsors-section {
    padding-bottom: 10px;
    overflow: auto;
    white-space: nowrap;
  }
  .sponsor-div {
    width: 80px;
    height: 80px;
    display: inline-block;
    margin-right: 8px;
    position: relative;
    background-color: #fff;
  }
  .sponsor-div:last-child {
    margin-right: 0;
  }
  .sponsor-div > img {
    height: 100%;
    width: 100%;
  }
  .sponsor-name {
    position: absolute;
    top: 3px;
    right: 4px;
    font-size: 5px;
    color: #aaa;
    height: 14px;
    overflow: hidden;
    word-break: break-word;
    width: 72px;
    text-align: right;
  }
}
</style>
